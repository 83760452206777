import React, { useState, useEffect, useRef } from "react";
import { Link, useStaticQuery, graphql, navigate } from "gatsby";
import { useFlexSearch } from "react-use-flexsearch";
import styled, { css } from "styled-components";
import SearchWhiteIcon from "./Icons/SearchWhite";
import { HtmlContent } from "./Content";

const SearchResultsStyles = styled.div`
  ${({ ...props }) => css`
    /* Common styles */
    position: absolute;
    top: 100%;
    background: #fff;
    z-index: 9999;

    width: 100%;
    right: 0;

    h4 {
      margin-bottom: 16px;
    }

    ol {
      margin-bottom: 24px;
      li {
        display: flex;
        img {
          width: 100px;
          margin-right: 16px;
        }
        a {
          padding: 4px 0;
          display: block;
          width: 100%;
        }
      }
    }

    p {
      text-align: center;
      border-top: solid 1px #eee;
      padding-top: 24px;
    }

    mark {
      color: ${props.theme.colors.primary};
      font-weight: 700;
    }

    /* Mobile only styles */
    @media (max-width: 991px) {
      height: calc(100vh - 136px);
      overflow: auto;
      padding: 24px 16px;
    }

    /* Desktop only styles */
    @media (min-width: ${props.theme.breakpoints.lg}) {
      width: 406px;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      box-shadow: -1px 5px 12px -9px #474747;
      max-height: 700px;
      overflow: auto;
      padding: 24px;
    }
  `}
`;

export default function SearchInputForm({ title, telephone, searchAction }) {
  const [query, setQuery] = useState(null);
  const [showResults, setShowResults] = useState(false);
  const inputRef = useRef(null);

  const data = useStaticQuery(graphql`
    query SearchIndexQuery {
      localSearchProducts {
        index
        store
      }
    }
  `);

  const index = data.localSearchProducts.index;
  const store = data.localSearchProducts.store;

  const handleInputOnChange = (e) => {
    let userQuery = e.target.value;
    if (userQuery.length > 2) {
      setQuery(userQuery);
    } else {
      setQuery(null);
    }
  };

  const handleLinkClick = (e) => {
    setQuery(null);
    searchAction();
    inputRef.current.value = "";
  };

  const results = useFlexSearch(query, index, store, { profile: "speed" });

  useEffect(() => {
    setShowResults(results.length > 0);
  }, [results, setShowResults]);

  useEffect(() => {
      document.body.addEventListener("click", hideResults);
    return function cleanup() {
      window.removeEventListener("click", hideResults);
    };
  }, []);

  const hideResults = (e) => {
    if (e.target.id !== "searchSubmit") {
      
     // console.log('hide results', e.target.id);
      /* setQuery(null);
      if(inputRef.current) {
        inputRef.current.value = "";
      } */

      setShowResults(false);
    }
  };

  const searchOnSubmit = (e) => {
    e.preventDefault();
    let url = `/search-results?q=${query}`;
    if (results.length === 0) {
      url = `${url}&null`
    }
    navigate(url, {
      state: { results },
    });
    searchAction();
  }
  return (
    <>
      <form
        onSubmit={searchOnSubmit}
      >
        <label htmlFor="search" className="sr-only">
          Search
        </label>
        <input
          type="search"
          name="search"
          id="search"
          placeholder="Search products or services"
          onChange={handleInputOnChange}
          onFocus={handleInputOnChange}
          ref={inputRef}
        />

        <button
          type="submit"
          title="Search products and services"
          id="searchSubmit"
        >
          <SearchWhiteIcon />
        </button>
      </form>
      {showResults ? (
        <SearchResultsStyles>
          {results.length > 0 && (
            <>
              <h4>Products</h4>
              <ol>
                {results.map((result) => {
                  const title = result.title.replace(
                    new RegExp(query, "gi"),
                    (match) => `<mark>${match}</mark>`
                  );
                  if (!result.removeFromSearch) {
                    return (
                      <li key={result.id}>
                        <Link
                          to={result.uri}
                          onClick={handleLinkClick}
                        >
                          <HtmlContent htmlString={title} />
                        </Link>
                      </li>
                    );
                  } else {
                    return <></>;
                  }
                })}
              </ol>
            </>
          )}
          <p>
            Found <strong>{results.length}</strong>{" "}
            results for <strong>"{query}"</strong>
          </p>
        </SearchResultsStyles>
      ) : (
        <></>
      )}
    </>
  );
}
