import React from "react";
import styled, { css } from "styled-components";
import Tooltippy from "./Tooltippy";
import { useStaticQuery, graphql } from "gatsby";

const StyledPricesMayVary = styled.div`
  ${({ ...props }) => css`
    > div {
      align-items: normal;

      svg {
        grid-column: 1;
        grid-row: 1;
        margin-left: 0;
        margin-top: .15rem;
      }
    }

    .prices_may_vary {
      text-align: center;
    }

    @media (min-width: ${props.theme.breakpoints.md}) {
      > div {
        svg {
          margin-top: .275rem;
        }
      }
      .prices_may_vary {
        max-width: 10rem;
        text-align: left;
      }
    }
  `}
`;

export default function PricesMayVary() {
  let data = {
    wp: {
      siteSettings: {
        siteSettings: {
          pricesMayVaryText: ''
        }
      }
    }
  }
  if (!process.env.STORYBOOK) {
    data = useStaticQuery(graphql`
      query PricesMayVaryQuery {
        wp {
          siteSettings {
            siteSettings {
              pricesMayVaryText
            }
          }
        }
      }
    `);
  }

  return (
    <StyledPricesMayVary>
      <Tooltippy
        text={
          <div dangerouslySetInnerHTML={{ __html: data.wp.siteSettings.siteSettings.pricesMayVaryText}}></div>
        }
      >
        <p className="prices_may_vary">Prices vary by quantity and delivery location</p>
      </Tooltippy>
    </StyledPricesMayVary>
  );
}
