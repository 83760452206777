import React from "react";

function CloseV2() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
      <path
        fill="#fff"
        d="m3.302 4.717 1.415-1.414 15.98 15.98-1.414 1.414z"
      />
      <path
        fill="#fff"
        d="m3.303 19.283 15.98-15.98 1.414 1.414-15.98 15.98z"
      />
    </svg>
  );
}

export default CloseV2;