import React from "react";
import { useStaticQuery, graphql } from "gatsby";

export const transformImageUrl = ({ siteUrl, imageUrl, width = null, height = null, fit = 'contain', quality = 100 }) => {
  // If it's an SVG, just return it
  if (typeof imageUrl === 'string' && imageUrl.endsWith('.svg')) {
    return imageUrl;
  }
  
  let transformedImageUrl = ''

  const isValidNumber = (value) => !isNaN(parseFloat(value)) && isFinite(value);

  if (typeof imageUrl === 'string') {
    transformedImageUrl = `${siteUrl}/.netlify/images?url=${encodeURIComponent(imageUrl)}&q=${quality}`;
    if (isValidNumber(width)) {
      transformedImageUrl += `&w=${Math.round(width * 1.5)}`
    }
    if (isValidNumber(height)) {
      transformedImageUrl += `&h=${Math.round(height * 1.5)}`
    }
    if (isValidNumber(width) && isValidNumber(height)) {
      transformedImageUrl += `&fit=${fit}`
    }
  }

  return transformedImageUrl
}

export default ({ image, alt, loading = 'lazy', width, height, fit = 'contain', quality = 100, decoding = 'async', fetchpriority = 'auto', className = '' }) => {
  let site = {
    siteMetadata: {
      siteUrl: 'https://safesitefacilities.co.uk'
    }
  }
  if (!process.env.STORYBOOK) {
    ({ site } = useStaticQuery(graphql`
      query SiteUrlQuery {
        site {
          siteMetadata {
            siteUrl
          }
        }
      }
    `))
  }

  return (
    <img src={transformImageUrl({ imageUrl: image, width, height, fit, quality, siteUrl: site.siteMetadata.siteUrl })} alt={alt} loading={loading} width={width} height={height} decoding={decoding} fetchpriority={fetchpriority} className={className} />
  )
}