import React from "react";
import styled, { css } from "styled-components";
import { Tooltip } from "react-tippy";
import "react-tippy/dist/tippy.css";

const TooltipStyles = styled.div`
  ${({ ...props }) => css`
    font-size: .9rem;
    background-color: #333;
    color: #fff;
    border-radius: 8px;
    width: 250px;
    text-align: center;
    margin: 12px;
    position: relative;
    z-index: 99999999999999999;
  `}
`;

const StyledTooltip = styled(Tooltip)`
  ${({ ...props }) => css`
    display: inline-grid !important;
    gap: .75rem;
    grid-template-columns: auto auto;
    align-items: center;
    svg {
      margin-left: 8px;
    }
  `}
`;

export default function Tooltippy({ text, children }) {
  return (
    <StyledTooltip
      html={<TooltipStyles>{text}</TooltipStyles>}
      position="top"
      trigger="mouseenter"
      arrow
      distance="20"
    >
      <span>{children}</span>
      <svg
        width="16"
        height="16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.75 8A7.75 7.75 0 0 1 8 15.75 7.75 7.75 0 1 1 8 .25c4.28 0 7.75 3.471 7.75 7.75ZM8.208 2.812c-1.703 0-2.79.718-3.642 1.993a.376.376 0 0 0 .085.508l1.084.822a.375.375 0 0 0 .52-.066c.559-.708.942-1.119 1.792-1.119.638 0 1.428.411 1.428 1.03 0 .468-.387.708-1.017 1.062-.735.412-1.708.925-1.708 2.208v.125c0 .207.168.375.375.375h1.75a.375.375 0 0 0 .375-.375v-.042c0-.89 2.6-.926 2.6-3.333 0-1.813-1.88-3.188-3.642-3.188ZM8 10.563A1.44 1.44 0 0 0 6.562 12 1.44 1.44 0 0 0 8 13.438 1.44 1.44 0 0 0 9.438 12 1.44 1.44 0 0 0 8 10.562Z"
          fill="#343534"
        />
      </svg>
    </StyledTooltip>
  );
}
