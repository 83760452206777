import React from "react"

export { wrapPageElement } from "./gatsby-shared"

export const onRouteUpdate = ({ location, prevLocation }) => {
  const isIe =
    /MSIE (\d+\.\d+);/.test(navigator.userAgent) ||
    navigator.userAgent.indexOf("Trident/") > -1;

  if (prevLocation !== null && window.__ctwnd) {
    if (!isIe) {
      window.__ctwnd.reinit();
    }
  }

  // There could be multiple free-quote elements on a page
  // So we need to dynamically hook in to places where #free-quote is the url from content entered by WP Editors
  // Then redirect to the correct de-duped freeQuoteElement
  // These elements get a string of characters prepended to their id, which is why the #free-quote does not work
  const links = document.querySelectorAll('a[href="#free-quote"]')
  const freeQuoteElement = document.querySelector('.free-quote')

  links.forEach((link) => {
    link.addEventListener('click', (event) => {
      if (freeQuoteElement) {
        event.preventDefault()

        window.location.hash = '#' + freeQuoteElement.id
      }
    })
  })
};
