import React from "react"
import { Script } from "gatsby"
import Layout from "./src/components/Layout"

export const wrapPageElement = ({ element, props }, themeOptions) => {
  // Check if we're in development or production mode
  const isDev = process.env.NODE_ENV === "development";

  return (
  <>
    <Layout {...props}>
      {element}
    </Layout>
    <script
      dangerouslySetInnerHTML={{
        __html: "document.documentElement.classList.add('js-enabled')",
      }}
    />
    {!isDev && themeOptions.optimizeId && (
      <Script
        src={`https://www.googleoptimize.com/optimize.js?id=${themeOptions.optimizeId}`}
      />
    )}
    <Script
      src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
    />
    <Script
      src="/livechat.js"
    />
    <Script
      src="/.netlify/functions/calltracks"
    />
  </>
  );
}
